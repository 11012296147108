<template>
  <hero-page hero="../../assets/@/assets/images_contact/AboutHero.png" :title="$t('contactHeading')">
    <v-row>
      <v-col cols="12" md="6">
        <img src="@/assets/contact_us/contact.png" width="100%" class="pa-6" style="max-width: 300px"
      /></v-col>
      <v-col cols="12" sm="6" class="pa-6">
        <h3>
          {{ $t("contactText") }}
        </h3>
        <table>
          <tr>
            <td><img src="@/assets/contact_us/email.svg" height="30px" width="30px" class="mr-6" /></td>
            <td>
              <p>{{ $t("contactEmail") }}</p>
            </td>
          </tr>

          <tr>
            <td><img src="@/assets/contact_us/whatsapp.svg" height="30px" width="30px" class="mr-6" /></td>
            <td>
              <p>{{ $t("contactWhatsApp") }}</p>
            </td>
          </tr>
          <tr>
            <td><img src="@/assets/contact_us/wechat.svg" height="30px" width="30px" class="mr-6" /></td>

            <td>
              <p>{{ $t("contactWeChat") }}</p>
            </td>
          </tr>
          <tr>
            <td><img src="@/assets/contact_us/line.svg" height="30px" width="30px" class="mr-6" /></td>
            <td>
              <p>{{ $t("contactLine") }}</p>
            </td>
          </tr>

          <tr>
            <td><img src="@/assets/contact_us/address.svg" height="30px" width="30px" class="mr-6" /></td>
            <td><p v-html="$t('contactAddress')"></p></td>
          </tr>
        </table>
      </v-col>
    </v-row>
  </hero-page>
</template>

<script>
import HeroPage from "../../components/Common/HeroPage";
import Landing from "@/layouts/Landing";

export default {
  name: "ContactUs",
  components: {
    HeroPage
  },
  created() {
    this.$emit(`update:layout`, Landing);
  }
};
</script>

<style scoped>
li {
  color: #000000;
  font-size: 12px;
}
p {
  margin: 0;
  color: #000000;
  font-size: 16px;
}
td {
  vertical-align: baseline;
}
</style>
